import Box from "@mui/material/Box";
import Divider from "@mui/material/Divider";
import BlogLayout from "src/components/BlogLayout";
import Paragraph from "src/components/Paragraph";
import SubHeader from "src/components/SubHeader";
import * as React from "react";
import camImg from "src/images/community/Cameron.jpg";
import shaImg from "src/images/community/Shalamar Carliss.jpg";

export default function ANewChapterPage() {
  return (
    <BlogLayout slug="a-new-chapter">
      <Paragraph>
        Individuals and families connect with EOCP at a point in their journey
        when they are seeking not just housing support, but a transformation in
        their lives. At EOCP, we meet individuals and families where they are,
        and stick with them as they move through the arduous yet impactful steps
        towards change.
      </Paragraph>
      <Divider sx={{ my: 4 }} />
      <Box
        component="img"
        src={camImg}
        sx={{ width: "50%", float: "right" }}
        ml={3}
        mb={3}
      />
      <SubHeader>Meet Cameron and his Daughter</SubHeader>
      <Paragraph>
        Cameron, a young father to a three-year-old daughter, came to EOCP’s
        young adult rapid re-housing program during the COVID-19 pandemic,
        seeking sustainable next steps for him and his child. When he first
        connected with EOCP, he was couch-surfing and hopping houses, looking
        for a sense of stability and empowerment as a parent and community
        member. With his dedicated EOCP Case Manager, Rob, he began the process
        of identifying a secure, appropriate, and permanent housing option.
        After initially finding a stable room with the support of EOCP, Cameron
        and his daughter are now living in a permanent housing placement — a
        one-bedroom apartment in Hayward!
      </Paragraph>

      <Paragraph>
        Beyond just the search and housing placement process, Cameron’s
        engagement with the opportunities of EOCP’s young adult program also
        enabled him to receive a rental subsidy, which will assist in his
        sustainability. He’s now working at an Amazon warehouse, saving money
        and building the stepping stones for a resilient and thriving future for
        his family.
      </Paragraph>

      <Paragraph>
        From the start, Cameron was able to see how EOCP’s program was a doorway
        to a new stage of life. He is on his way to completing the program next
        year, and he is staying very diligent about making the most of the
        resources that EOCP offers. Cameron stays in frequent touch with Rob,
        building knowledge and skills about how to improve himself and his
        daughter’s life, as well as their bond together as parent and child.
      </Paragraph>

      <Paragraph>
        Cameron’s orientation to learning and growth, combined with the belief
        and dedication of EOCP’s team, have granted a new beginning for this
        family.
      </Paragraph>
      <Divider sx={{ my: 4 }} />
      <Box
        component="img"
        src={shaImg}
        sx={{ width: "50%", float: "left" }}
        mr={3}
        mb={3}
      />
      <SubHeader>Meet Shalamar and her Children</SubHeader>
      <Paragraph>
        When we most recently connected with Shalamar and her two children in
        2019, she was in a moment of frustration, and in need of shelter. She
        had previously been placed in permanent housing, but had encountered
        personal challenges in maintaining her rent subsidy. Shalamar had been
        experiencing the ins and outs of the administrative barriers to finding
        reliable housing, and she was tired. She knew that she needed help.{" "}
      </Paragraph>

      <Paragraph>
        Shalamar had moved through EOCP’s program a few years prior. At EOCP, we
        know that big change takes time, and we show up for all individuals and
        families, no matter where they’re at in their process. This time,
        Shalamar was ready, fired up, and open-minded to the core message we
        coach people towards at EOCP: We’ll provide the tools and scaffolding,
        but the transformation you seek comes from your individual efforts and
        commitment to being your own inner resource.
      </Paragraph>

      <Paragraph>
        Taking the reins of success for herself and her young children, Shalamar
        dove into the housing search and selection process, all while living in
        a temporary, hotel-based shelter provided by EOCP. She researched
        landlords, units, and areas alongside her EOCP Case Manager, Tambra, who
        ensured she had the financial assistance she needed, and advocated for
        her at crucial points. Shalamar took on a security job and picked up the
        working hours that would keep her family in their home.{" "}
      </Paragraph>

      <Paragraph>
        It’s been an incredible journey to her current stage. Shalamar just
        moved into a three-bedroom house supported by a subsidy from Oakland
        Housing Authority, in an area where her children will still continue in
        the same schools. Securing the subsidy was made possible by Shalamar’s
        dedication to deeply understanding every phase of the process and
        potential barriers to tenancy — she anticipated challenges and faced
        them head on! She truly partnered with Tambra to clarify, learn, and
        empower herself. She didn’t miss a beat, and was so elated to learn
        about her OHA subsidy — not only for the permanent home it reassured,
        but because she knew she had seen herself through the process.{" "}
      </Paragraph>

      <Paragraph>
        The path to securing housing for her and her family was just the spark
        plug for Shalamar’s transformation. Her experiences have propelled her
        into community leadership and activism. Seeing the need for other
        mothers in Oakland to connect with resources and each other, she has
        mobilized people through texts and email to see and utilize the support
        that EOCP offers, even proactively setting up a food and clothing
        donation drive on-site. With bold, entrepreneurial spirit, Shalamar is
        manifesting her dreams — she started doing stand-up comedy, performing
        in local clubs and finding momentum. Shalamar has moved from being a
        client to an embodiment of EOCP’s mission, demonstrating the
        possibilities that exist.{" "}
      </Paragraph>
    </BlogLayout>
  );
}
